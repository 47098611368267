import config from './config';
import i18n from '../i18n';

export const status = {
    OK: 200,
    CREATED: 201,
    NO_STORE: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    MANY_REQUEST: 429,
    EXPIRED_SESSION: 503,
    ERROR: 500,
};

export const PUBLISH_PLATFORM = {
    LADIPAGE: 'LADIPAGE',
    LADIPAGEDNS: 'LADIPAGEDNS',
    WORDPRESS: 'WORDPRESS',
    SAPO: 'SAPO',
    HARAVAN: 'HARAVAN',
    ITOPWEBSITE: 'ITOPWEBSITE',
    SHOPIFY: 'SHOPIFY',
    FTP: 'FTP',
    OTHER: 'OTHER'
};


export const PACKAGES = {
    STARTER: 'STARTER',
    LITE: 'LITE',
    STANDARD: 'STANDARD',
    PRO: 'PRO',
    BUSINESS: 'BUSINESS',
    ENTERPRISE: 'ENTERPRISE'
}

// PREMIUM = BUSINESS
export const OAUTH_PUBLISH_PLATFORM = ['HARAVAN', 'SAPO', 'SHOPIFY'];
export const API_KEY_PUBLISH_PLATFORM = ['ITOPWEBSITE', 'WORDPRESS'];


export const endpoint = {
    // --------------------LUID------------------------
    PROFILE: `${config.ACCOUNT_UID}/profile`,
    // --------------------LF-------------------------
    STORE_INFO: `${config.API_V2_0}/store/info`,
    STORE_UPDATE: `${config.API_V2_0}/store/update`,
    STORE_LIST: `${config.API_V2_0}/partner/get-stores`,

    // --------------------LADIPAGE------------------------
    OAUTH_HARAVAN: `${config.API_V2_0}/oauth/haravan`,
    OAUTH_SAPO: `${config.API_V2_0}/oauth/sapo`,
    OAUTH_SAPO_ACCESS: `${config.API_V2_0}/oauth/sapo-access`,
    OAUTH_SHOPIFY: `${config.API_V2_0}/oauth/shopify`,
    OAUTH_SHOPIFY_ACCESS: `${config.API_V2_0}/oauth/shopify-access`,

    DOMAIN_LIST: `${config.API_V2_0}/domain/list`,
    DOMAIN_DELETE: `${config.API_V2_0}/domain/delete`,
    DOMAIN_SHOW: `${config.API_V2_0}/domain/show`,
    DOMAIN_CREATE: `${config.API_V2_0}/domain/create`,
    DOMAIN_UPDATE: `${config.API_V2_0}/domain/update`,
    DOMAIN_ON_SSL: `${config.API_V2_0}/domain/onssl`,
    DOMAIN_UPDATE_404PAGE: `${config.API_V2_0}/domain/update404-page`,
    DOMAIN_VERIFY: `${config.API_V2_0}/domain/verify`,
    DOMAIN_UPDATE_SCOPE: `${config.API_V2_0}/domain/update-scope`,

    LADIPAGE_LIST: `${config.API_V2_0}/ladi-page/list`,
    LADIPAGE_SHOW: `${config.API_V2_0}/ladi-page/show`,
    LADIPAGE_LIST_STAFF: `${config.API_V2_0}/staff/list`,
    LADIPAGE_LIST_TEAM: `${config.API_V2_0}/team/list`,
    LADIPAGE_CREATE: `${config.API_V2_0}/ladi-page/create`,
    LADIPAGE_DELETE: `${config.API_V2_0}/ladi-page/delete`,
    LADIPAGE_UN_DELETE: `${config.API_V2_0}/ladi-page/un-delete`,
    LADIPAGE_LIST_SCOPE: `${config.API_V2_0}/ladi-page/list-scope`,
    LADIPAGE_STOP: `${config.API_V2_0}/ladi-page/stop`,
    LADIPAGE_REPORT: `${config.API_V2_0}/ladi-page/report`,
    LADIPAGE_IMPORT: `${config.API_V2_0}/ladi-page/import`,
    LADIPAGE_UPDATE: `${config.API_V2_0}/ladi-page/update`,
    LADIPAGE_UPDATE_SCOPE: `${config.API_V2_0}/ladi-page/update-scope`,
    LADIPAGE_DATA_BACKUP: `${config.API_V2_0}/ladi-page/data-backup`,
    LADIPAGE_EXPORT_HTML: `${config.API_V2_0}/ladi-page/export-html`,
    LADIPAGE_EXPORT_LADIPAGE: `${config.API_V2_0}/ladi-page/export`,
    LADIPAGE_ON_ACCESS_KEY: `${config.API_V2_0}/ladi-page/update-access-key`,
    LADIPAGE_OFF_ACCESS_KEY: `${config.API_V2_0}/ladi-page/update-access-key`,

    // --------------------TAG------------------------
    LADIPAGE_TAG_LIST_BY_ID: `${config.API_V2_0}/ladi-page-tag/list-name`,
    TAG_LIST_LADIPAGE: `${config.API_V2_0}/ladi-page-tag/list`,
    TAG_LIST_BANNER: `${config.API_V2_0}/banner-tag/list`,
    TAG_LIST_POPUP: `${config.API_V2_0}/ladi-page-tag/list`,

    TAG_CREATE_LADIPAGE: `${config.API_V2_0}/ladi-page-tag/create`,
    TAG_CREATE_BANNER: `${config.API_V2_0}/banner-tag/create`,
    TAG_CREATE_POPUP: `${config.API_V2_0}/ladi-page-tag/create`,

    TAG_UPDATE_LADIPAGE: `${config.API_V2_0}/ladi-page-tag/update`,
    TAG_UPDATE_BANNER: `${config.API_V2_0}/banner-tag/update`,
    TAG_UPDATE_POPUP: `${config.API_V2_0}/ladi-page-tag/update`,

    TAG_DELETE_LANDINGPAGE: `${config.API_V2_0}/ladi-page-tag/delete`,
    TAG_DELETE_BANNER: `${config.API_V2_0}/banner-tag/delete`,
    TAG_DELETE_POPUP: `${config.API_V2_0}/ladi-page-tag/delete`,

    TAG_UPDATE_SCOPE_LANDINGPAGE: `${config.API_V2_0}/ladi-page-tag/update-scope`,
    TAG_UPDATE_SCOPE_POPUP: `${config.API_V2_0}/ladi-page-tag/update-scope`,
    TAG_UPDATE_SCOPE_BANNER: `${config.API_V2_0}/banner-tag/update-scope`,

    TAG_SHOW_LADIPAGE: `${config.API_V2_0}/ladi-page-tag/show`,
    TAG_SHOW_POPUP: `${config.API_V2_0}/ladi-page-tag/show`,
    TAG_SHOW_BANNER: `${config.API_V2_0}/banner-tag/show`,

    // --------------------BANER ADS------------------------
    LIST_TAG_BANNER_ADS: `${config.API_V2_0}/banner-tag/list-by-id`,
    BANNER_LIST: `${config.API_V2_0}/banner/list`,
    BANNER_CREATE: `${config.API_V2_0}/banner/create`,
    BANNER_UPDATE: `${config.API_V2_0}/banner/update`,
    BANNER_DELETE: `${config.API_V2_0}/banner/delete`,
    BANNER_SHOW: `${config.API_V2_0}/banner/show`,
    BANNER_UN_DELETE: `${config.API_V2_0}/banner/un-delete`,
    BANNER_EXPORT_LADIPAGE: `${config.API_V2_0}/banner/export`,
    BANNER_LIST_SCOPE: `${config.API_V2_0}/banner/list-scope`,
    BANNER_IMPORT: `${config.API_V2_0}/banner/import`,
    BANNER_UPDATE_SCOPE: `${config.API_V2_0}/banner/update-scope`,
    BANNER_DELETE_SCOPE: `${config.API_V2_0}/banner/delete-scope`,

    // --------------------POPUP------------------------
    POPUPX_LIST: `${config.API_V2_0}/ladi-page/list`,
    POPUPX_CREATE: `${config.API_V2_0}/ladi-page/create`,
    POPUPX_UPDATE: `${config.API_V2_0}/ladi-page/update`,
    POPUPX_DELETE: `${config.API_V2_0}/ladi-page/delete`,
    POPUPX_SHOW: `${config.API_V2_0}/ladi-page/show`,
    POPUPX_EXPORT_LADIPAGE: `${config.API_V2_0}/ladi-page/export`,
    POPUPX_LIST_SCOPE: `${config.API_V2_0}/ladi-page/list-scope`,
    POPUPX_UPDATE_SCOPE: `${config.API_V2_0}/ladi-page/update-scope`,
    POPUPX_DELETE_SCOPE: `${config.API_V2_0}/ladi-page/delete-scope`,
    POPUPX_IMPORT: `${config.API_V2_0}/ladi-page/import`,
    POPUPX_STOP: `${config.API_V2_0}/ladi-page/stop`,

    // --------------------TEMPLATE------------------------
    TEMPLATE_LIST: `${config.API_V2_0}/theme-list`,
    TEMPLATE_TAG_LIST: `${config.API_V2_0}/theme-tag-list`,

    // --------------------SHOWCASE------------------------
    SHOWCASE_LIST: `${config.API_V2_0}/theme/list-showcase`,
    //----------------SETTING/ACTIVITY---------------------
    ACTIVITY_LIST: `${config.API_V2_0}/activity/get-list`,

    // --------------------TEAM-----------------------
    TEAM_LIST: `${config.API_V2_0}/team/list`,
    TEAM_CREATE: `${config.API_V2_0}/team/create`,
    TEAM_DELETE: `${config.API_V2_0}/team/delete`,
    TEAM_UPDATE: `${config.API_V2_0}/team/update`,

    //-------------------- STAFF-----------------------
    STAFF_LIST: `${config.API_V2_0}/staff/list`,
    STAFF_CREATE: `${config.API_V2_0}/staff/create`,
    STAFF_UPDATE: `${config.API_V2_0}/staff/update`,
    STAFF_DELETE: `${config.API_V2_0}/staff/delete`,
    STAFF_ADD: `${config.API_V2_0}/team/add-scope`,
    STAFF_REMOVE: `${config.API_V2_0}/team/remove-scope`,
    STAFF_UPDATE_SCOPE: `${config.API_V2_0}/team/update-scope`,

    //---------------------ACCESS-----------------------
    ACCESS_LIST: `${config.API_V2_0}/access/list`,
    ACCESS_CREATE: `${config.API_V2_0}/access/create`,
    ACCESS_DELETE: `${config.API_V2_0}/access/delete`,
    ACCESS_UPDATE: `${config.API_V2_0}/access/update`,
    //--------------------ACCESS_KEY-----------------------
    ACCESS_KEY_LIST: `${config.API_V2_0}/access-key/list`,
    ACCESS_KEY_CREATE: `${config.API_V2_0}/access-key/create`,
    ACCESS_KEY_RESET_LOGIN: `${config.API_V2_0}/access-key/reset-login`,
    ACCESS_KEY_DELETE: `${config.API_V2_0}/access-key/delete`,

    //---------------------FUNNEL-----------------------
    FUNNEL_LIST: `${config.API_V2_0}/funnel/list`,
    FUNNEL_CREATE: `${config.API_V2_0}/funnel/create`,
    FUNNEL_RUN: `${config.API_V2_0}/funnel/run`,
    FUNNEL_STOP: `${config.API_V2_0}/funnel/stop`,
    FUNNEL_UPDATE: `${config.API_V2_0}/funnel/update`,
    FUNNEL_DELETE: `${config.API_V2_0}/funnel/delete`,
    FUNNEL_REPORT: `${config.API_V2_0}/funnel/report`,

    // --------------------INTEGRATIONS-----------------
    FORM_ACCOUNT_LIST: `${config.API_V2_0}/form-account/list`,
    FORM_ACCOUNT_SHOW: `${config.API_V2_0}/form-account/show`,
    FORM_ACCOUNT_DELETE: `${config.API_V2_0}/form-account/delete`,
    FORM_ACCOUNT_UPDATE_SCOPE: `${config.API_V2_0}/form-account/update-scope`,
    FORM_CONFIG_LIST: `${config.API_V2_0}/form-config/list`,
    FORM_CONFIG_SHOW: `${config.API_V2_0}/form-config/show`,
    FORM_CONFIG_UPDATE_SCOPE: `${config.API_V2_0}/form-config/update-scope`,
    FORM_CONFIG_UPDATE: `${config.API_V2_0}/form-config/update`,
    FORM_CONFIG_DELETE: `${config.API_V2_0}/form-config/delete`,
    TRACKING_GLOBAL_LIST: `${config.API_V2_0}/tracking-global/list`,
    TRACKING_GLOBAL_SHOW: `${config.API_V2_0}/tracking-global/show`,
    TRACKING_GLOBAL_UPDATE_SCOPE: `${config.API_V2_0}/tracking-global/update-scope`,
    TRACKING_GLOBAL_DELETE: `${config.API_V2_0}/tracking-global/delete`,

    //---------------------DYNAMIC----------------------
    DYNAMIC_LIST: `${config.API_V2_0}/campaign/list`,
    DYNAMIC_REPORT: `${config.API_V2_0}/campaign/report`,
    DYNAMIC_STOP: `${config.API_V2_0}/campaign/stop`,
    DYNAMIC_RUN: `${config.API_V2_0}/campaign/run`,
    DYNAMIC_DELETE: `${config.API_V2_0}/campaign/delete`
};



export default {
    LANGUAGES: [
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-vi.svg',
            code: 'vi',
            name: 'Vietnamese',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-en.svg',
            code: 'en',
            name: 'English',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-th.svg',
            code: 'th',
            name: 'Thailand',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-id.svg',
            code: 'id',
            name: 'Indonesia',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-my.svg',
            code: 'my',
            name: 'Malaysia',
        }
    ],
    COOKIE: {
        LANGUAGE: 'LANGUAGE'
    },
    MY_INFO: 'XY3J6326-kCAYN10v',
    ERROR: 'ERROR',
    TYPE_SAVE: {
        SAVE_DRAF: 'SAVE_DRAF',
        SAVE_POST: 'SAVE_POST',
    },
    COOKIE: {
        SSID: 'SSID',
        STORE_ID: 'STORE_ID',
        TEAM_ID: 'TEAM_ID',
        EXPIRE: 365,
        LANGUAGE: 'LANGUAGE'
    },
    LOCAL_FORAGE: {
        COUNTRY_KEY: 'COUNTRIES',
        STATE_KEY: 'STATES',
        DISTRICT_KEY: 'DISTRICTS',
        WARD_KEY: 'WARDS',
        USER_INFO: 'USER_INFO',
        RECENT_PARTNRER_CODE: 'RECENT_PARTNRER_CODE',
        FLOW_DRAFT_INFO: 'FLOW_DRAFT_INFO',
        FAN_PAGE_LIST: 'FAN_PAGE_LIST',
    },
    LADIFLOW_PRICE: 'https://www.ladiflow.vn/banggia',
    VER: '2.0',
    DEFAULT_FORMAT_DATE_TIME: 'DD/MM/YYYY HH:mm',
    DEFAULT_DATE: 'DD/MM/YYYY',
    DEFAULT_FORMAT_DATE: 'dd/MM/yyyy',
    REPORT_OVERTIME_FORMAT_DATE: 'dd/MM',
    DEFAULT_DATE_PICKER_FORMAT_DATE: 'dd-MM-yyyy',
    DEFAULT_TIME: 'HH:mm',
    DB_DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm:ss',
    DEFAULT_DATE_YEAR: 'YYYY/MM/DD',
    DEFAULT_SES: {
        ID: 'DEFAULT_SES',
        NAME: 'Email Testing',
    },
    PAGINATION: {
        DEFAULT_LIMIT: 10,
        LIMIT_OPTIONS_PRODUCT: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
        ],
        LIMIT_OPTIONS: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 }
        ],
        LIMIT_OPTIONS_TEST: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 30, name: 30 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
            { value: 1000, name: 1000 },
        ],
    },
    URL_IMAGE: {
        TABLE_TAG: "https://w.ladicdn.com/ladiui/ladipage/icon-ldp-table-tag.svg",
        ICON_ADD: "https://w.ladicdn.com/ladiui/ladipage/icon-ldp-add.svg",
        BANNER_LANDINGPAGE: "https://w.ladicdn.com/ladiui/ladipage/banner-landingpage.svg",
        BANNER_ADS: "https://w.ladicdn.com/ladiui/ladipage/banner-banner-ads.svg",
        BANNER_POPUP: "https://w.ladicdn.com/ladiui/ladipage/banner-popupx.svg",
        BACKGROUD_DYNAMIC: "https://w.ladicdn.com/ladiui/ladipage/banner-dynamic.svg",
        BACKGROUD_FUNNEL: "https://w.ladicdn.com/ladiui/ladipage/banner-funnel.svg",
        BACKGROUD_ACCESS: "https://w.ladicdn.com/ladiui/ladipage/banner-access.svg?v=1",
        NOTIFICATION_ICON: "https://w.ladicdn.com/ladiui/ladipage/icon-ldp-notification.svg",
        BACKGROUD_TEAM: "https://w.ladicdn.com/ladiui/ladipage/banner-team.svg",
        BACKGROUD_DOMAIN: "https://w.ladicdn.com/ladiui/ladipage/banner-domain.svg",
        BACKGROUD_FORM_ACCOUNT: 'https://w.ladicdn.com/ladiui/ladipage/banner-form-acount.svg',
        BACKGROUD_FORM_CONFIG: 'https://w.ladicdn.com/ladiui/ladipage/banner-form-config.svg',
        BACKGROUD_TRACKING_GLOBAL: 'https://w.ladicdn.com/ladiui/ladipage/banner-teacking.svg',
    },
    STATUS_OPTIONS: [
        { value: 1, name: `${i18n.t('STATUS.ACTIVE')}` },
        { value: 0, name: `${i18n.t('STATUS.INACTIVE')}` },
    ],
    FORM_MODE: {
        CREATE: 'CREATE',
        EDIT: 'EDIT',
        VIEW: 'VIEW',
    },
    APP: {
        LP: 'lp',
        LF: 'lf',
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
    },
    MODE: {
        TEST: 0,
        PRODUCTION: 1,
    },
    GENDER: {
        MALE: 'male',
        FEMALE: 'female',
    },
    WHITE_LOGO: 'https://w.ladicdn.com/source/v3/logo_ladiflow_neg.svg',
    COLOR_LOGO: 'https://w.ladicdn.com/source/v3/logo_ladiflow_origin.svg',
    DOMAIN_CDN: 'https://w.ladicdn.com',
    DOMAIN_S_CDN: 'https://s.ladicdn.com',
    RESIZE: {
        THUMB: 's150x150',
        MEDIUM: 's600x600',
    },
    PKG: {
        STARTER: 'STARTER',
        PREMIUM: 'PREMIUM',
    },
    COMMON: {
        DELETE_TYPE: {
            SINGLE: 'SINGLE',
        },
    },
    TYPE_IMAGE: {
        SUBMIT_IMAGE: 'SUBMIT_IMAGE',
        SUBMIT_GALLERY: 'SUBMIT_GALLERY',
        CLOSE: 'CLOSE',
        CLOSE_GALLERY: 'CLOSE_GALLERY',
        SAVE_URL: 'SAVE_URL',
        IMAGE: 'IMAGE',
        GALLERY: 'GALLERY',
        CHANGE_TITLE: 'CHANGE_TITLE',
        CHANGE_IMAGE: 'CHANGE_IMAGE',
        LIST: 'LIST',
        CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION',
        ADD_URL_GALLERY: 'ADD_URL_GALLERY',
        VIDEO: 'VIDEO',
        FILE: 'FILE',
        CHANGE_TEXT_LIST: 'CHANGE_TEXT_LIST',
        TYPING: 'TYPING',
        AUDIO: 'AUDIO',
    },
    UPLOAD_FILE: {
        MAX_SIZE: {
            VIDEO: 24117248,
            IMAGE: 5242880,
            DOCUMENT: 24117248,
        },

        NOT_ACCEPT: ['bat', 'exe'],
        IMAGE: ['jpg', 'png', 'jpeg', 'gif', 'svg'],
        VIDEO: ['mp4', 'avi', 'wmv', '3gp', 'flv'],
        FILE: ['csv', 'txt', 'pdf', 'doc', 'xlsx'],
        AUDIO: ['mp3'],
        TYPE: {
            IMAGE: 'IMAGE',
            VIDEO: 'VIDEO',
            AUDIO: 'AUDIO',
            DOCUMENT: 'DOCUMENT',
        },
    },
    INTEGRATION: {
        TYPE: {
            DEFAULT: 'DEFAULT',
            SMTP: 'SMTP',
            EMAIL: 'EMAIL',
            LADIMAIL: 'LADIMAIL',
            ESMS: 'ESMS',
            FB_PAGE: 'FB_PAGE',
            ZALO: 'ZALO',
            LADILINK: 'LADILINK',
            HARAVAN: 'HARAVAN',
            SAPO: 'SAPO',
            SHOPIFY: 'SHOPIFY',
            WORDPRESS: 'WORDPRESS',
            KIOTVIET: 'KIOTVIET',
            GETRESPONSE: 'GETRESPONSE',
            MAIL_CHIMP: 'MAIL_CHIMP',
            GOOGLE_SHEET: 'GOOGLE_SHEET',
            INFUSION_SOFT: 'INFUSION_SOFT',
            HUBSPOT: 'HUBSPOT',
            ACTIVE_CAMPAIGN: 'ACTIVE_CAMPAIGN',
            CONVERTKIT: 'CONVERTKIT',
            NHANH: 'NHANH',
            AI_CALL: 'AI_CALL',
            INSTAGRAM: 'INSTAGRAM',
        },
    },
    LANGUAGES: [
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-vi.svg',
            code: 'vi',
            name: 'Vietnamese',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-en.svg',
            code: 'en',
            name: 'English',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-th.svg',
            code: 'th',
            name: 'Thailand',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-id.svg',
            code: 'id',
            name: 'Indonesia',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-my.svg',
            code: 'my',
            name: 'Malaysia',
        }
    ],
    TIMEZONE: {
        MINUS_TWELVE: {
            code: -12,
            name: 'UTC-12:00',
        },
        MINUS_ELEVEN: {
            code: -11,
            name: 'UTC-11:00',
        },
        MINUS_TEN: {
            code: -10,
            name: 'UTC-10:00',
        },
        MINUS_NINE: {
            code: -9,
            name: 'UTC-9:00',
        },
        MINUS_EIGHT: {
            code: -8,
            name: 'UTC-8:00',
        },
        MINUS_SEVEN: {
            code: -7,
            name: 'UTC-7:00',
        },
        MINUS_SIX: {
            code: -6,
            name: 'UTC-6:00',
        },
        MINUS_FIVE: {
            code: -5,
            name: 'UTC-5:00',
        },
        MINUS_FOUR: {
            code: -4,
            name: 'UTC-4:00',
        },
        MINUS_THREE: {
            code: -3,
            name: 'UTC-3:00',
        },
        MINUS_TWO: {
            code: -2,
            name: 'UTC-2:00',
        },
        MINUS_ONE: {
            code: -1,
            name: 'UTC-1:00',
        },
        ZERO: {
            code: 0,
            name: 'UTC+0:00',
        },
        ONE: {
            code: 1,
            name: 'UTC+1:00',
        },
        TWO: {
            code: 2,
            name: 'UTC+2:00',
        },
        THREE: {
            code: 3,
            name: 'UTC+3:00',
        },
        FOUR: {
            code: 4,
            name: 'UTC+4:00',
        },
        FIVE: {
            code: 5,
            name: 'UTC+5:00',
        },
        SIX: {
            code: 6,
            name: 'UTC+6:00',
        },
        SEVEN: {
            code: 7,
            name: 'UTC+7:00',
        },
        EIGHT: {
            code: 8,
            name: 'UTC+8:00',
        },
        NINE: {
            code: 9,
            name: 'UTC+9:00',
        },
        TEN: {
            code: 10,
            name: 'UTC+10:00',
        },
        ELEVEN: {
            code: 11,
            name: 'UTC+11:00',
        },
        TWELVE: {
            code: 12,
            name: 'UTC+12:00',
        },
        THIRTEEN: {
            code: 13,
            name: 'UTC+13:00',
        },
        FOURTEEN: {
            code: 14,
            name: 'UTC+14:00',
        },
    },
    ICON: {
        SAPO: 'https://w.ladicdn.com/s600x600/5e548427be8067214346f471/gcalls-logo-doi-tac-sapo-1-20210708084900.png',
        LADILINK: 'https://w.ladicdn.com/source/v3/icon_ladilink_origin.svg',
        HARAVAN: 'https://builder.ladipage.com/img/haravan.png',
        SHOPIFY: 'https://builder.ladipage.com/img/shopify.png',
        WORDPRESS: 'https://builder.ladipage.com/img/wordpress.png',
        KIOTVIET: 'https://builder.ladipage.com/img/kiotviet.png',
        GETRESPONSE: 'https://builder.ladipage.com/img/get_response.png',
        ACTIVE_CAMPAIGN: 'https://builder.ladipage.com/img/active_campaign.png',
        HUBSPOT: 'https://builder.ladipage.com/img/hubspot.png',
        INFUSION_SOFT: 'https://builder.ladipage.com/img/infusion_soft.png',
        GOOGLE_SHEET: 'https://builder.ladipage.com/img/google_sheet.png',
        CONVERTKIT: 'https://builder.ladipage.com/img/convertkit.png',
        MAIL_CHIMP: 'https://builder.ladipage.com/img/mail_chimp.png',
        NHANH: 'https://builder.ladipage.com/img/nhanh_vn.png',
        AI_CALL: 'https://w.ladicdn.com/s600x600/5e548427be8067214346f471/4306077-20211011041149.png',

        ZALO: 'https://w.ladicdn.com/5ef1d77068f0886ddcf9ac93/icons8-zalo-20201224031436.svg',
        INSTAGRAM: 'https://w.ladicdn.com/5f9fa8a09b5c4d6ce6e3a77d/instagram-20220119072531.svg',
        WOOCOMMERCE: 'https://cdn.worldvectorlogo.com/logos/woocommerce.svg',
        LADISALES: 'https://w.ladicdn.com/source/v3/icon_ladisales_origin.svg',
        LADIFLOW: 'https://w.ladicdn.com/source/v3/icon_ladiflow_origin.svg',
        LADICHAT: 'https://w.ladicdn.com/source/v3/icon_ladichat_origin.svg',
    },
    TESTER_ID: ['5f7af2abee45222f88b8bb9a', '5e4cdd33b05aa85cedd8243e', '5e548427be8067214346f471', '5f92a2fba5fb857312142d24', '5f9fa8a09b5c4d6ce6e3a77d',
        '61de474c4b97810250215579', '61db98dc6602b90012bda441', '59dad4b1bc6e48fab2041f06', '5f8e4d6cd4e5462e7853c501', '609eb4b9e77c73001366e4fb', '5f7ecfbc4eb72b1e297c472b',
        '5faa3d6d39595d75be63c036', '603c452b48e10f0011be8c90', '5e55f01bbe8067214346f48d', '613ffa731905c800127ee8dc', '6113a638309ca5001238df0b', '60bb4c11e2206c0012ed81cb',
        '602c93e499af3a0012a84f29', '61de474c4b97810250215579', '608790903e489159203f09f7', '5e55e686be8067214346f476', '6228525c2abeb26b38f4925d'],
    APP_NAME: {
        LP: 'LadiPage',
        LC: 'LadiChat',
        LS: 'LadiSale'
    }
};
