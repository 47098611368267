import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/const';

const signIn = (data) => ({
    type: types.SIGNIN,
    meta: {
        endpoint: endpoint.AUTH_SIGNIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: false,
    },
    search: data.search
});

const signUp = (data) => ({
    type: types.SIGNUP,
    meta: {
        endpoint: endpoint.AUTH_SIGNUP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: false,
    },
    event_track: data.event_track,
    search: data.search
});

const signUpVerify = (data) => ({
    type: types.SIGNUP_VERIFY,
    meta: {
        endpoint: endpoint.SIGNUP_VERIFY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: false,
    },
    event_track: data.event_track,
    search: data.search
});

const resendOtp = (data) => ({
    type: types.RESEND_OTP,
    meta: {
        endpoint: endpoint.RESEND_OTP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
});

const submitOtp = (data) => ({
    type: types.SUBMIT_OTP,
    meta: {
        endpoint: endpoint.SUBMIT_OTP,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: false,
    },
});

const signOut = () => ({
    type: types.SIGNOUT,
});

const getUserInfo = (data) => ({
    type: types.GET_USER_INFO,
    meta: {
        endpoint: endpoint.AUTH_USER_INFO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
    search: data.search,
    path: data.path || false
});

const updateUserInfo = (formdata) => ({
    type: types.UPDATE_USER_INFO,
    meta: {
        endpoint: endpoint.UPDATE_USER_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
        form: formdata
    }
});

const changePassword = (body) => ({
    type: types.CHANGE_PASSWORD,
    meta: {
        endpoint: endpoint.CHANGE_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true,
        showNotify: true
    }
});

const forgottenPassword = (body) => ({
    type: types.FORGOTTEN_PASSWORD,
    meta: {
        endpoint: endpoint.FORGOTTEN_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: false
    }
});

const sendVerifyEmail = (body) => ({
    type: types.VERIFY_EMAIL,
    meta: {
        endpoint: endpoint.VERIFY_EMAIL,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: true
    }
});

const resetPassword = (body) => ({
    type: types.RESET_PASSWORD,
    meta: {
        endpoint: endpoint.RESET_PASSWORD,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: false
    }
});

const getUrlFacebook = (refObject) => ({
    type: types.GET_URL_FACEBOOK,
    meta: {
        endpoint: endpoint.GET_URL_FACEBOOK,
        method: REQUEST_METHOD.POST,
        body: refObject,
        hasAuth: false
    },
});

const oauth2Facebook = (callbackObj) => ({
    type: types.OAUTH2_FACEBOOK,
    meta: {
        endpoint: endpoint.OAUTH2_FACEBOOK,
        method: REQUEST_METHOD.POST,
        body: callbackObj,
        hasAuth: false
    },
    search: callbackObj.search,
    path: callbackObj.path || false
});

const getUrlGoole = (refObject) => ({
    type: types.GET_URL_GOOGLE,
    meta: {
        endpoint: endpoint.GET_URL_GOOGLE,
        method: REQUEST_METHOD.POST,
        body: refObject,
        hasAuth: false
    },
});

const oauth2Goole = (callbackObj) => ({
    type: types.OAUTH2_GOOGLE,
    meta: {
        endpoint: endpoint.OAUTH2_GOOGLE,
        method: REQUEST_METHOD.POST,
        body: callbackObj,
        hasAuth: false
    },
    search: callbackObj.search,
    path: callbackObj.path || false
});

const logoutAll = (data) => ({
    type: types.LOGOUT_ALL,
    meta: {
        endpoint: endpoint.LOGOUT_ALL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const completeRegister = (data) => ({
    type: types.COMPLETE_REGISTER,
    meta: {
        endpoint: endpoint.COMPLETE_REGISTER,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    },
    search: data.search
});

const changeAffilateCode = (data) => ({
    type: types.CHANGE_AFFILATE_CODE,
    meta: {
        endpoint: endpoint.CHANGE_AFFILATE_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const changeFlowAffilateCode = (data) => ({
    type: types.CHANGE_FLOW_AFFILATE_CODE,
    meta: {
        endpoint: endpoint.CHANGE_FLOW_AFFILATE_CODE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});


const getRefAffilate = () => ({
    type: types.GET_REF_AFFILATE,
    meta: {
        endpoint: endpoint.GET_REF_AFFILATE,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});

const getRefferralInfo = () => ({
    type: types.GET_REFFERRAL_INFO,
    meta: {
        endpoint: endpoint.GET_REFFERRAL_INFO,
        method: REQUEST_METHOD.POST,
        body: {},
        hasAuth: true,
    }
});


const verifyEmail = (body) => ({
    type: types.SUBMIT_VERIFY_EMAIL,
    meta: {
        endpoint: endpoint.SUBMIT_VERIFY_EMAIL,
        method: REQUEST_METHOD.POST,
        body,
        hasAuth: false
    }
});

export default {
    signIn,
    signUp,
    signUpVerify,
    resendOtp,
    submitOtp,
    signOut,
    getUserInfo,
    getRefAffilate,
    getRefferralInfo,
    updateUserInfo,
    changePassword,
    changeAffilateCode,
    changeFlowAffilateCode,
    forgottenPassword,
    resetPassword,
    getUrlFacebook,
    getUrlGoole,
    oauth2Facebook,
    oauth2Goole,
    logoutAll,
    completeRegister,
    sendVerifyEmail,
    verifyEmail
}