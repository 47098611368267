import axios from 'axios';
import * as commonTypes from '../futures/common/types';
import {
    SIGNIN, SIGNUP, SIGNOUT, GET_USER_INFO, UPDATE_USER_INFO, CHANGE_PASSWORD, RESET_PASSWORD, FORGOTTEN_PASSWORD, OAUTH2_FACEBOOK, OAUTH2_GOOGLE, COMPLETE_REGISTER,
    SUBMIT_OTP, SIGNUP_VERIFY
} from '../futures/auth/types';
import appConst, { status } from '../../config/const';
import _ from 'lodash';
import { push } from 'connected-react-router';

import queryString from 'query-string';
import baseHelper from '../../helpers/BaseHelper';
import i18n from '../../i18n';

export const REQUEST_METHOD = {
    GET: 'get',
    POST: 'post',
}

const serviceMiddleware = store => next => action => {

    if (action.meta) {
        // console.log('===== META: ', action,' waitting=', action.meta);
        next({ type: commonTypes.ASYNC_START, waitting: action.type });
        const { endpoint, body, method, hasAuth, form, showNotify } = action.meta;

        if (body)
            body.lang = baseHelper.getCookie(appConst.COOKIE.LANGUAGE) || 'vi';

        const req = {
            method: method,
            url: endpoint,
            credentials: 'include',
            headers: requestHeaders(hasAuth, form),
            data: body ? body : form
        };

        axios(req).then(function (response) {
            if (action.type != commonTypes.ASYNC_END && action.type != commonTypes.ASYNC_START) {
                // console.log('Action = ', action.type, ' - Response = ',response);
            }
            const { data } = response;
            if (_.includes([status.OK, status.CREATED], data.code)) {
                action.status = true;
                action.payload = data.data;
                action.message = data.message;
            } else if (data.code === status.UNAUTHORIZED) {
                baseHelper.removeCookie(appConst.USER_INFO);
                baseHelper.removeCookie(appConst.LADI_UID_TOKEN);

                const search = action.search || '';

                store.dispatch(push('/signin' + search));
                return;
            } else {
                action.status = false;
                action.message = data.message;
            }

            if (showNotify) {
                if (action.status)
                    window.LadiUI.toast('success', '', action.message, 5000, 'bottom-left');
                else
                    window.LadiUI.toast('danger', '', action.message, 5000, 'bottom-left');
            }

            next(action);
            next({ type: commonTypes.ASYNC_END, waitting: null });
        }).catch(function (error) {
            action.status = false;
            let message = error.message;
            if (error.response && error.response.data)
                message = error.response.data.message;
            action.message = message;
            window.LadiUI.showErrorMessage(i18n.t('BASE.NOTIFICATION'), message, 'OK');

            next(action);
            next({ type: commonTypes.ASYNC_END, waitting: null });
        });

        return;
    }
    next(action);
}

const localStorageMiddleware = store => next => action => {
    let track = false;
    switch (action.type) {
        case SIGNUP:
        case COMPLETE_REGISTER:
        case OAUTH2_FACEBOOK:
        case OAUTH2_GOOGLE:
            if (action.status && action.payload && action.payload.is_new) {
                track = "complete"
            }
            break;
    }
    switch (action.type) {
        // case SIGNUP_VERIFY:
        //     if (action.status) {
        //         console.log('action.payload.user = ', action.payload.user);
        //         if (action.payload.user) {
        //             baseHelper.setCookie(appConst.LADI_UID_TOKEN, action.payload.user.token, appConst.COOKIE_EXPRIRE);
        //             baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(action.payload.user), appConst.COOKIE_EXPRIRE);
        //         }
        //     }
        //     break;
        case COMPLETE_REGISTER:
            baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(action.payload), appConst.COOKIE_EXPRIRE);
            break;
        case SIGNIN:
        case SIGNUP:

        case SUBMIT_OTP:
        case GET_USER_INFO:
        case UPDATE_USER_INFO:
        case OAUTH2_FACEBOOK:
        // case COMPLETE_REGISTER:
        case OAUTH2_GOOGLE:
            if (action.status) {
                if (action.payload.token) {
                    baseHelper.setCookie(appConst.LADI_UID_TOKEN, action.payload.token, appConst.COOKIE_EXPRIRE);
                }
                // alert(action.payload.token);
                // console.log('parent: ', window.parent);
                const parsedUrl = new window.URLSearchParams(window.location.search);
                if (parsedUrl.get('iframe') == 1 && action.type != COMPLETE_REGISTER) {
                    if (window.parent) {
                        let token = action.payload.token;
                        if (!token) token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);

                        // console.log('send parent: ', {
                        //     ladi_uid: {
                        //         token,
                        //         redirect_url: window.location.origin + '/iframe?app_code=' + parsedUrl.get('app_code') + '&callback_url=' + encodeURIComponent(parsedUrl.get('callback_url')) + '&token=' + token,
                        //         is_auth: true
                        //     }
                        // })
                        window.parent.postMessage(JSON.stringify({
                            ladi_uid: {
                                token,
                                redirect_url: window.location.origin + '/iframe?app_code=' + parsedUrl.get('app_code') + '&callback_url=' + encodeURIComponent(parsedUrl.get('callback_url')) + '&token=' + token,
                                is_auth: true
                            }
                        }), '*');
                    }
                    return;
                }


                if (window.gtag) {
                    // console.log('callback = ', action.payload.data)
                    let eventName = 'Signin_Success';
                    switch (action.type) {
                        case SIGNUP:
                            eventName = 'Register_success';
                            break;
                        case OAUTH2_FACEBOOK:
                            eventName = 'Facebook_login_success';
                            break;
                        case OAUTH2_GOOGLE:
                            eventName = 'Google_login_success';
                            break;
                        case GET_USER_INFO:
                        case UPDATE_USER_INFO:
                        case COMPLETE_REGISTER:
                            eventName = false;
                            break;
                    }
                    if (action.payload.data && action.payload.data.event_track) {
                        eventName = action.payload.data.event_track;
                    }
                    if (eventName) {
                        window.gtag('event', eventName, {
                            'event_category': 'LadiUID',
                            'event_label': 'Test'
                        });
                    }
                }
                if (action.type == SIGNIN || action.type == GET_USER_INFO) {
                    const user = action.payload;
                    if (user.is_not_verify) {
                        const { verify_type, exp_time } = user;
                        const token = user.token || baseHelper.getCookie(appConst.LADI_UID_TOKEN);
                        store.dispatch(push(`/verify-account?verify_type=${verify_type}&email=${user.email}&phone=${user.phone}&exp_time=${exp_time}&token=${token}`));
                        baseHelper.removeCookie(appConst.LADI_UID_TOKEN);
                        return;
                    }
                }
                baseHelper.setCookie(appConst.USER_INFO, JSON.stringify(action.payload), appConst.COOKIE_EXPRIRE);
                window.LadiUserInfo = function getClientInfo() {
                    return { user_info: action.payload };
                }
                if (!baseHelper.getCookie(appConst.COOKIE.LANGUAGE))
                    i18n.changeLanguage(action.payload.language || 'vi');
                else
                    i18n.changeLanguage(baseHelper.getCookie(appConst.COOKIE.LANGUAGE));

                let search = action.search || '';
                if (!action.payload.phone) {
                    store.dispatch(push('/complete-reg?' + search));
                    return;
                }

                let callbackURL = false;
                let parsed = {};
                const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);
                // Neu co get param tren trinh duyet
                // console.log('; searcu = ', search)
                if (search != '') {
                    parsed = queryString.parse(search);
                    delete parsed.logout;
                    delete parsed.utm_source;
                    search = queryString.stringify(parsed);
                    callbackURL = parsed.callback_url;
                    let appCode = parsed.app_code;
                    if (!callbackURL && appCode && appCode.toLowerCase() == 'lp' && track == 'complete') {
                        callbackURL = appConst.DEFAULT_CALLBACK_URL;
                    }
                }

                // neu tao account moi va ko co get params
                if (action.payload && action.payload.is_new && !callbackURL) {
                    callbackURL = appConst.DEFAULT_CALLBACK_URL;
                }

                // Co callback thi se call back
                // ko co call back nhung appcode = lp va register thi redirect ve builder
                if (callbackURL && baseHelper.validCallback(callbackURL)) {

                    const parseCallback = queryString.parseUrl(callbackURL);
                    delete parsed.callback_url;
                    if (track) {
                        parseCallback.query['track'] = track;
                    }
                    parsed.token = token;
                    parseCallback.query['token'] = token;

                    if (action.type == SIGNIN ||
                        action.type == SIGNUP ||
                        action.type == OAUTH2_FACEBOOK ||
                        action.type == COMPLETE_REGISTER ||
                        action.type == OAUTH2_GOOGLE) {
                        parseCallback.query['login_callback'] = 1;

                    }
                    const LadiClientID = baseHelper.getCookie(appConst.LADI_CLIENT_ID);
                    if (LadiClientID && LadiClientID != '') {
                        parseCallback.query['LADI_CLIENT_ID'] = LadiClientID;
                    }
                    const query = queryString.stringify(parseCallback.query);
                    const isDelay = (parseCallback.url.indexOf('builder.ladipage.com') >= 0 || parseCallback.url.indexOf('builder.dev.ladipage.com'));
                    if ((action.type == SIGNUP || action.type == COMPLETE_REGISTER) && isDelay) {
                        setTimeout(() => {
                            window.location.href = parseCallback.url + '?' + query;
                        }, 1500);
                    } else {
                        window.location.href = parseCallback.url + '?' + query;
                    }

                    // console.log(parseCallback.url + '?' + query);
                } else {
                    if (action.path) {
                        if (_.includes(['/signin', '/signup', '/oauth2-facebook', '/oauth2-google', '/', '/verify-account'], action.path)) {
                            store.dispatch(push('/account/profile'));
                        }
                        else {
                            const pathRedirect = action.path + '?' + search;
                            store.dispatch(push(pathRedirect));
                        }
                    } else {
                        store.dispatch(push('/account/profile'));
                    }
                }


            }
            break;

        case SIGNOUT:
            baseHelper.removeCookie(appConst.USER_INFO);
            baseHelper.removeCookie(appConst.LADI_UID_TOKEN);
            store.dispatch(push('/signin?logout=1'));
            break;

        case CHANGE_PASSWORD:
            if (action.payload && action.payload.token) {
                baseHelper.setCookie(appConst.LADI_UID_TOKEN, action.payload.token, baseHelper.setCookie);
            }

            break;

        case RESET_PASSWORD:
            if (action.status) {
                store.dispatch(push('/signin'));
            }
            break;

    }
    next(action);
};

function requestHeaders(hasAuth = true, form) {
    const headers = (form)
        ? {
            'Content-Type': 'multipart/form-data',
        } : {
            'Content-Type': 'application/json',
        }
    if (!hasAuth) {
        return headers;
    }

    const token = baseHelper.getCookie(appConst.LADI_UID_TOKEN);

    headers.authorization = token;

    return headers;
}


export { serviceMiddleware, localStorageMiddleware }